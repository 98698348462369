/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const produceNotification = /* GraphQL */ `
  mutation ProduceNotification {
    produceNotification
  }
`
export const createIntegration = /* GraphQL */ `
  mutation CreateIntegration($input: IntegrationSettings!) {
    createIntegration(input: $input)
  }
`
export const getJiraProjects = /* GraphQL */ `
  mutation GetJiraProjects($input: getProjects!) {
    getJiraProjects(input: $input)
  }
`
export const createJiraIntegration = /* GraphQL */ `
  mutation CreateJiraIntegration($input: AWSJSON) {
    createJiraIntegration(input: $input)
  }
`
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      tablePartitionKey
      tableSortKey
      displayName
      organizationRole
      __typename
    }
  }
`
export const addOrgSubscription = /* GraphQL */ `
  mutation AddOrgSubscription($input: SubscriptionInput!) {
    addOrgSubscription(input: $input) {
      tablePartitionKey
      tableSortKey
      displayName
      organizationRole
      integrationSource
      integrationTag
      details
      worklogAuthor
      initiator
      initiatorGroupMembers
      timespent
      timestamp
      project
      workchunk
      title
      settings
      access
      filters {
        name
        value
        __typename
      }
      filterName
      enabled
      description
      integrationStatus
      dataSource
      organization
      worktime
      userSub
      allUsers
      atlassianId
      status
      createdAt
      updatedAt
      manualAtlassianId
      seats
      paidSeats
      tier
      monthlyFee
      billingEnd
      ttl
      stripePriceId
      stripeCustomerId
      planLimits
      planPrice
      secondaryAccounts
      primaryAccount
      customerData
      invoiceNumber
      invoiceDates
      invoiceLines
      invoiceTotals
      jiraAccessToken
      jiraRefreshToken
      userEmail
      __typename
    }
  }
`
export const updateUserWorktime = /* GraphQL */ `
  mutation UpdateUserWorktime($input: UserWorktimeInput!) {
    updateUserWorktime(input: $input) {
      tablePartitionKey
      tableSortKey
      worktime
      __typename
    }
  }
`
export const refreshIntegration = /* GraphQL */ `
  mutation RefreshIntegration($input: SubscriptionData) {
    refreshIntegration(input: $input)
  }
`
export const createGroups = /* GraphQL */ `
  mutation CreateGroups($input: CreateGroupsInput!) {
    createGroups(input: $input) {
      tableSortKey
      initiatorGroupMembers
      displayName
      __typename
    }
  }
`
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup($input: DeleteGroupInput!) {
    deleteGroup(input: $input)
  }
`
export const createStripeCheckout = /* GraphQL */ `
  mutation CreateStripeCheckout($input: CreateStripeCheckoutInput!) {
    createStripeCheckout(input: $input) {
      Url
      __typename
    }
  }
`
export const changeStripePlan = /* GraphQL */ `
  mutation ChangeStripePlan($input: ChangeStripePlanInput!) {
    changeStripePlan(input: $input)
  }
`
export const stripeCustomerManagement = /* GraphQL */ `
  mutation StripeCustomerManagement($input: StripeCustomerManagementInput!) {
    stripeCustomerManagement(input: $input)
  }
`
export const addMember = /* GraphQL */ `
  mutation AddMember($input: AddMemberInput!) {
    addMember(input: $input) {
      tableSortKey
      organizationRole
      status
      atlassianId
      secondaryAccounts
      primaryAccount
      createdAt
      manualAtlassianId
      __typename
    }
  }
`
export const removeMember = /* GraphQL */ `
  mutation RemoveMember($input: RemoveMemberInput!) {
    removeMember(input: $input)
  }
`
export const DeleteIntegrationOsLogsUser = /* GraphQL */ `
  mutation DeleteIntegrationOsLogsUser(
    $input: DeleteIntegrationOsLogsUserInput!
  ) {
    DeleteIntegrationOsLogsUser(input: $input)
  }
`
export const setMemberStatus = /* GraphQL */ `
  mutation SetMemberStatus($input: SetMemberStatusInput!) {
    setMemberStatus(input: $input)
  }
`
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization($input: DeleteOrganizationInput!) {
    deleteOrganization(input: $input)
  }
`
export const joinOrganization = /* GraphQL */ `
  mutation JoinOrganization($input: JoinOrganizationInput!) {
    joinOrganization(input: $input) {
      tableSortKey
      organizationRole
      status
      atlassianId
      secondaryAccounts
      primaryAccount
      createdAt
      manualAtlassianId
      __typename
    }
  }
`
export const addEditManualWorklog = /* GraphQL */ `
  mutation AddEditManualWorklog($input: IntegrationDataInput!) {
    addEditManualWorklog(input: $input) {
      userSub
      timestamp
      project
      tablePartitionKey
      details
      timespent
      initiator
      workchunk
      tableSortKey
      worklogAuthor
      dataSource
      worklogOwner
      tags
      integrationTag
      __typename
    }
  }
`
export const deleteIntegrationDataByQuery = /* GraphQL */ `
  mutation DeleteIntegrationDataByQuery(
    $input: DeleteIntegrationDataByQueryInput!
  ) {
    deleteIntegrationDataByQuery(input: $input)
  }
`
export const deleteIntegrationDataByID = /* GraphQL */ `
  mutation DeleteIntegrationDataByID($worklogID: String!) {
    deleteIntegrationDataByID(worklogID: $worklogID)
  }
`
export const createNotification = /* GraphQL */ `
  mutation CreateNotification($input: NotificationSettings!) {
    createNotification(input: $input) {
      tablePartitionKey
      tableSortKey
      organization
      classification
      messageBody
      seen
      title
      timestamp
      ttl
      dataSource
      actionGetters
      createdAt
      updatedAt
      __typename
    }
  }
`
export const addTag = /* GraphQL */ `
  mutation AddTag($input: TagInput!) {
    addTag(input: $input) {
      tableSortKey
      __typename
    }
  }
`
export const editTag = /* GraphQL */ `
  mutation EditTag($input: EditTagInput!) {
    editTag(input: $input) {
      tableSortKey
      __typename
    }
  }
`
export const addCustomFilter = /* GraphQL */ `
  mutation AddCustomFilter($input: AddCustomFilterInput!) {
    addCustomFilter(input: $input) {
      tablePartitionKey
      tableSortKey
      filters {
        name
        value
        __typename
      }
      name
      access
      settings
      __typename
    }
  }
`
export const removeCustomFilter = /* GraphQL */ `
  mutation RemoveCustomFilter($input: RemoveCustomFilterInput!) {
    removeCustomFilter(input: $input) {
      tablePartitionKey
      tableSortKey
      filters {
        name
        value
        __typename
      }
      name
      access
      settings
      __typename
    }
  }
`
export const shareFilterWithReader = /* GraphQL */ `
  mutation ShareFilterWithReader($input: ShareFilterWithReaderInput!) {
    shareFilterWithReader(input: $input)
  }
`
export const linkAccounts = /* GraphQL */ `
  mutation LinkAccounts($input: LinkAccountsInput!) {
    linkAccounts(input: $input)
  }
`
export const linkSlaveToMaster = /* GraphQL */ `
  mutation LinkSlaveToMaster($input: LinkAccountsInput!) {
    linkSlaveToMaster(input: $input) {
      tablePartitionKey
      tableSortKey
      displayName
      organizationRole
      integrationSource
      integrationTag
      details
      worklogAuthor
      initiator
      initiatorGroupMembers
      timespent
      timestamp
      project
      workchunk
      title
      settings
      access
      filters {
        name
        value
        __typename
      }
      filterName
      enabled
      description
      integrationStatus
      dataSource
      organization
      worktime
      userSub
      allUsers
      atlassianId
      status
      createdAt
      updatedAt
      manualAtlassianId
      seats
      paidSeats
      tier
      monthlyFee
      billingEnd
      ttl
      stripePriceId
      stripeCustomerId
      planLimits
      planPrice
      secondaryAccounts
      primaryAccount
      customerData
      invoiceNumber
      invoiceDates
      invoiceLines
      invoiceTotals
      jiraAccessToken
      jiraRefreshToken
      userEmail
      __typename
    }
  }
`
export const unlinkAccounts = /* GraphQL */ `
  mutation UnlinkAccounts($input: LinkAccountsInput!) {
    unlinkAccounts(input: $input)
  }
`
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification($input: DeleteNotificationInput!) {
    deleteNotification(input: $input) {
      tablePartitionKey
      tableSortKey
      organization
      classification
      messageBody
      seen
      title
      timestamp
      ttl
      dataSource
      actionGetters
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteWildmetricRefreshIntegrationRecords = /* GraphQL */ `
  mutation DeleteWildmetricRefreshIntegrationRecords(
    $input: DeleteWildmetricRefreshIntegrationRecordsInput!
    $condition: ModelwildmetricRefreshIntegrationRecordsConditionInput
  ) {
    deleteWildmetricRefreshIntegrationRecords(
      input: $input
      condition: $condition
    ) {
      integrationSource
      ttl
      organization
      createdAt
      updatedAt
      __typename
    }
  }
`
export const createWildmetricRefreshIntegrationRecords = /* GraphQL */ `
  mutation CreateWildmetricRefreshIntegrationRecords(
    $input: CreateWildmetricRefreshIntegrationRecordsInput!
    $condition: ModelwildmetricRefreshIntegrationRecordsConditionInput
  ) {
    createWildmetricRefreshIntegrationRecords(
      input: $input
      condition: $condition
    ) {
      integrationSource
      ttl
      organization
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteJoinOrganizationRequest = /* GraphQL */ `
  mutation DeleteJoinOrganizationRequest($input: DeleteJoinOrganizationInput!) {
    deleteJoinOrganizationRequest(input: $input) {
      tablePartitionKey
      tableSortKey
      status
      __typename
    }
  }
`
export const deleteWildmetricCollectionRecords = /* GraphQL */ `
  mutation DeleteWildmetricCollectionRecords(
    $input: DeleteWildmetricCollectionRecordsInput!
    $condition: ModelwildmetricCollectionRecordsConditionInput
  ) {
    deleteWildmetricCollectionRecords(input: $input, condition: $condition) {
      tablePartitionKey
      tableSortKey
      displayName
      organizationRole
      integrationSource
      integrationTag
      details
      worklogAuthor
      initiator
      initiatorGroupMembers
      timespent
      timestamp
      project
      workchunk
      title
      settings
      access
      filters {
        name
        value
        __typename
      }
      filterName
      enabled
      description
      integrationStatus
      dataSource
      organization
      worktime
      userSub
      allUsers
      atlassianId
      status
      createdAt
      updatedAt
      manualAtlassianId
      seats
      paidSeats
      tier
      monthlyFee
      billingEnd
      ttl
      stripePriceId
      stripeCustomerId
      planLimits
      planPrice
      secondaryAccounts
      primaryAccount
      customerData
      invoiceNumber
      invoiceDates
      invoiceLines
      invoiceTotals
      jiraAccessToken
      jiraRefreshToken
      userEmail
      __typename
    }
  }
`
export const updateWildmetricCollectionRecords = /* GraphQL */ `
  mutation UpdateWildmetricCollectionRecords(
    $input: UpdateWildmetricCollectionRecordsInput!
    $condition: ModelwildmetricCollectionRecordsConditionInput
  ) {
    updateWildmetricCollectionRecords(input: $input, condition: $condition) {
      tablePartitionKey
      tableSortKey
      displayName
      organizationRole
      integrationSource
      integrationTag
      details
      worklogAuthor
      initiator
      initiatorGroupMembers
      timespent
      timestamp
      project
      workchunk
      title
      settings
      access
      filters {
        name
        value
        __typename
      }
      filterName
      enabled
      description
      integrationStatus
      dataSource
      organization
      worktime
      userSub
      allUsers
      atlassianId
      status
      createdAt
      updatedAt
      manualAtlassianId
      seats
      paidSeats
      tier
      monthlyFee
      billingEnd
      ttl
      stripePriceId
      stripeCustomerId
      planLimits
      planPrice
      secondaryAccounts
      primaryAccount
      customerData
      invoiceNumber
      invoiceDates
      invoiceLines
      invoiceTotals
      jiraAccessToken
      jiraRefreshToken
      userEmail
      __typename
    }
  }
`
export const createWildmetricCollectionRecords = /* GraphQL */ `
  mutation CreateWildmetricCollectionRecords(
    $input: CreateWildmetricCollectionRecordsInput!
    $condition: ModelwildmetricCollectionRecordsConditionInput
  ) {
    createWildmetricCollectionRecords(input: $input, condition: $condition) {
      tablePartitionKey
      tableSortKey
      displayName
      organizationRole
      integrationSource
      integrationTag
      details
      worklogAuthor
      initiator
      initiatorGroupMembers
      timespent
      timestamp
      project
      workchunk
      title
      settings
      access
      filters {
        name
        value
        __typename
      }
      filterName
      enabled
      description
      integrationStatus
      dataSource
      organization
      worktime
      userSub
      allUsers
      atlassianId
      status
      createdAt
      updatedAt
      manualAtlassianId
      seats
      paidSeats
      tier
      monthlyFee
      billingEnd
      ttl
      stripePriceId
      stripeCustomerId
      planLimits
      planPrice
      secondaryAccounts
      primaryAccount
      customerData
      invoiceNumber
      invoiceDates
      invoiceLines
      invoiceTotals
      jiraAccessToken
      jiraRefreshToken
      userEmail
      __typename
    }
  }
`
export const createWildmetricCountriesTimezones = /* GraphQL */ `
  mutation CreateWildmetricCountriesTimezones(
    $input: CreateWildmetricCountriesTimezonesInput!
    $condition: ModelwildmetricCountriesTimezonesConditionInput
  ) {
    createWildmetricCountriesTimezones(input: $input, condition: $condition) {
      code
      countryName
      zones
      createdAt
      updatedAt
      __typename
    }
  }
`
export const updateWildmetricCountriesTimezones = /* GraphQL */ `
  mutation UpdateWildmetricCountriesTimezones(
    $input: UpdateWildmetricCountriesTimezonesInput!
    $condition: ModelwildmetricCountriesTimezonesConditionInput
  ) {
    updateWildmetricCountriesTimezones(input: $input, condition: $condition) {
      code
      countryName
      zones
      createdAt
      updatedAt
      __typename
    }
  }
`
export const deleteWildmetricCountriesTimezones = /* GraphQL */ `
  mutation DeleteWildmetricCountriesTimezones(
    $input: DeleteWildmetricCountriesTimezonesInput!
    $condition: ModelwildmetricCountriesTimezonesConditionInput
  ) {
    deleteWildmetricCountriesTimezones(input: $input, condition: $condition) {
      code
      countryName
      zones
      createdAt
      updatedAt
      __typename
    }
  }
`
